import React from 'react';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import styles from './LeftSideNav.module.css';
import Logo from '../Logo/Logo';

const LeftSideNav = () => (
    <nav>
        <div className={styles.mainleftbar}>
            <div className={styles.logo}>
                <Logo  />
            </div>
            <NavigationItems />
        </div>
    </nav>
);

export default LeftSideNav;