import React from 'react';
import styles from './NavigationItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const navigationItem = (props) => {
    if (props.link.startsWith("http")) {
        return (
        <li className={styles.ListItem}>
            <a href={`${props.link}`} className={styles.hreflink}>
                <FontAwesomeIcon className={styles.Icon} icon={`${props.icon}`} />
                <h4 className={styles.linkTitle}>{`${props.children}`}</h4>
            </a>
        </li>
        );
    }   
    else {
        return (
        <li className={styles.ListItem}>
            <Link to={`${props.link}`} className={styles.hreflink}>
                <FontAwesomeIcon className={styles.Icon} icon={`${props.icon}`} />
                <h4 className={styles.linkTitle}>{`${props.children}`}</h4>
            </Link>
        </li>
        );
    }
};

export default navigationItem;