import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    loading: false
};

const loadingStart = (state) => {
    return updateObject(state, {loading: true});
};

const loadingSuccess = (state) => {
    return updateObject(state, {loading: false});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START: return loadingStart(state);
        case actionTypes.LOADING_SUCCESS: return loadingSuccess(state);
        default: return state;
    }
};

export default reducer;