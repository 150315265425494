import React, { Component } from 'react';
import styles from './Layout.module.css';
import { connect } from 'react-redux';
import Topbar from '../Topbar/Topbar';
import LeftSideNav from '../LeftSideNav/LeftSideNav';
import {Container, Row, Col} from 'react-bootstrap';
import Footer from '../Footer/Footer';

class Layout extends Component {
    state = {
        
    }

    render() {
        return (
        <Container fluid>
            <Topbar className={styles.topBar} />
            <Row>
                <Col xs={2} className={styles.leftSideBar}>
                    <LeftSideNav />
                </Col>
                <Col xs={10} className={styles.main_content}>
                    {this.props.children}
                </Col>
            </Row>
            <Footer />
        </Container>
        );
    }    
}

export default connect()(Layout);