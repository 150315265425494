import React from 'react';
import styles from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';


const navigationItems = () => (
    <div className={styles.mainleftbar}>
        <ul className={styles.List}>
            <NavigationItem icon="tachometer-alt" link="/" exact>Overview</NavigationItem>
        </ul>
    </div>
);

export default navigationItems;