import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from '../../hoc/asyncComponent/asyncComponent';
import Layout from '../../components/Layout/Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faTachometerAlt} from '@fortawesome/free-solid-svg-icons';
library.add(faCogs, faTachometerAlt);

const asyncOverview = asyncComponent(() => {
  return import('../Overview/Overview');
});

class App extends Component {
  render() {
    return (
      <div>
        <Layout>
          <Switch>
            <Route path="/" exact component={asyncOverview} />
            <Route render={() =><Redirect from="*" to="/" />} />
          </Switch>
        </Layout>
      </div>
    );
  };
}

export default withRouter(connect()(App));