import React from 'react';
import styles from './Footer.module.css';
import { Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <Row className={styles.Footer}>
            <Col xs={4}></Col>
            <Col xs={5}>
                <p className={styles.copy}>Dealer Spike Marketing &copy; {(new Date()).getFullYear()}</p>
            </Col>
            <Col xs={3}>
                <p className={styles.version}>{process.env.REACT_APP_NAME} | Version: {process.env.REACT_APP_VERSION}</p>
            </Col>
        </Row>
    );
}
 
export default Footer;